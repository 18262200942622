import axios from 'axios';
import { URL } from '../config';
import StorageService from './StorageService';
import CryptoService from './CryptoService';

/* eslint no-console: "off" */

const http = axios.create({ baseURL: URL });

http.interceptors.request.use(async (request) => {
  let TOKEN = '';
  const userData = StorageService.getItem('user_data');
  const decryptedUserData = CryptoService.decryptObj(userData);
  console.log('request', request);
  if (decryptedUserData) TOKEN = decryptedUserData.token;
  if (request.url.match(/registration/gi)) {
    request.headers = {
      ...request.headers,
    };
  } else {
    request.headers = {
      ...request.headers,
      Authorization: `Token ${TOKEN}`,
    };
  }

  return request;
});

http.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log('ERROR', error);
    return Promise.reject();
  }
);

export const getBrandsList = (searchWord) => {
  console.log('GET BRANDS');
  return http.get('/api/v1/brands/', {
    params: {
      without_pagination: true,
      all: true,
      search: searchWord,
      title_only: true,
      include_without_products: true,
    },
  });
};

export const getBrand = (id) => {
  return http.get(`/api/v1/brands/${id}/`);
};

export const registerUser = (username) => {
  return http.post('/api/v1/rest-auth/registration/', {
    username,
  });
};

export const bodyAnswers = (shoulders, chest, waist) => {
  console.log(`
  shoulders_chest: ${shoulders},
  chest_waist: ${chest},
  waist_hips: ${waist}
  `);
  return http.post('/api/v1/my-body-answers/', {
    shoulders_chest: shoulders,
    chest_waist: chest,
    waist_hips: waist,
  });
};

export const favoriteBrands = (data) => {
  console.log(data);
  return http.post('/api/v1/brand-likes/', data);
};

export const brandsSizes = (brand, mainSize, optionalSize) => {
  console.log({
    brand,
    main_size: mainSize,
    optional_size: optionalSize,
  });
  return http.post('/api/v1/brand-sizes/', {
    brand,
    main_size: mainSize === '40+' ? 41 : Number(mainSize),
    optional_size: optionalSize === '40+' ? 41 : Number(optionalSize),
  });
};

export const sendAnswer = (question, answer) => {
  console.log({
    question,
    answer,
  });

  return http.post('/api/v1/answers/', {
    question,
    answer,
  });
};

export default http;
