import React, { useEffect } from 'react';
import Select from '../../organisms/Select';

export default ({
  firstText,
  secondText,
  onChange = () => {},
  didMountCallback = () => {},
  didUpdateCallback = () => {},
  disabled = false,
  onFocus = () => {},
  onBlur = () => {},
  selects = [
    {
      value: '',
      placeholder: '',
      name: '',
      options: []
    }
  ]
}) => {
  useEffect(() => {
    didMountCallback();
  }, []);

  useEffect(() => {
    didUpdateCallback();
  });

  const onChangeHandler = name => text => {
    onChange(name, text);
  };

  const focusHandler = name => () => {
    onFocus(name);
  };

  return (
    <div className="question">
      <div className="question-cont">
        <div className="question-line">
          <span className="back-text">{firstText}</span>
          {selects.map(select => (
            <Select
              withoutChange={disabled}
              key={select.id}
              options={select.options}
              placeholder={select.value || select.placeholder}
              onChange={onChangeHandler(select.name)}
              onFocus={focusHandler(select.name)}
              onBlur={onBlur}
              disabled={disabled}
              id={select.id}
            />
          ))}
          <span className="back-text">{secondText}</span>
        </div>
      </div>
    </div>
  );
};
