/* eslint-disable */
import React, { Component } from 'react';
import Scroll from 'react-scroll';
import { observer } from 'mobx-react';
import disableScroll from 'disable-scroll';
import viewportStore from '../../stores/viewportStore/index';
const Element = Scroll.Element;
const scroller = Scroll.scroller;

@observer
class QuestionScroller extends Component {
  state = {
    initial: true,
    scroll: false,
  };
  touchStartPosition = null;
  timer = null;
  pageY = 0;
  scrollerSelected = false;
  scroller = null;
  itemsHeight = '';

  componentDidMount() {
    const { current, final, disabled, viewedItem } = this.props;
    window.addEventListener('wheel', this.onScroll);
    window.addEventListener('mousewheel', this.onScroll);
    setTimeout(() => {
      this.setState({ initial: false });
    }, 100);
    setTimeout(() => {
      if (!final && !disabled && current > 2) {
        this.scrollToItem(viewedItem || current - 3);
      }
    }, 1500);
  }

  componentDidUpdate(prevProps) {
    const { current, viewedItem } = this.props;
    if (viewedItem && current !== prevProps.current) {
      setTimeout(() => {
        this.scrollToItem(viewedItem);
      }, 1500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.onScroll);
    window.removeEventListener('mousewheel', this.onScroll);
    this.enablePageScrolling();
  }

  disablePageScrolling = () => {
    const { final, disabled } = this.props;
    this.scrollerSelected && !final && !disabled && disableScroll.on();
  };

  enablePageScrolling = () => {
    disableScroll.off(this.scroller);
  };

  onScroll = (e) => {
    const { onPrev = () => {}, onNext = () => {}, disabled, current, final } = this.props;
    const rect = this.scroller.getBoundingClientRect();
    if (
      e.clientX < rect.left + rect.width &&
      e.clientX > rect.left &&
      e.pageY > rect.top + window.pageYOffset &&
      e.pageY < rect.top + window.pageYOffset + rect.height
    ) {
      if (disabled || final) {
        this.enablePageScrolling();
      }
      this.scrollerSelected = true;
    } else {
      this.scrollerSelected = false;
    }

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (!this.scroller) return;
      if (this.scrollerSelected) {
        if (e.deltaY < 0) {
          !disabled && onNext();
        } else {
          !disabled &&
            !final &&
            current !== 0 &&
            scroller.scrollTo(`item-${0}`, {
              duration: 500,
              delay: 100,
              smooth: true,
              containerId: 'scrollerOverflow',
            });
          !disabled && onPrev();
        }
      }
    }, 100);
  };

  scrollToItem = (index, num) => {
    const topOffset = this.getTopPos(num);
    scroller.scrollTo(`item-${index}`, {
      duration: 500,
      delay: 100,
      smooth: true,
      containerId: 'scrollerOverflow',
      offset: topOffset,
    });
  };

  onClickHandler = (index) => () => {
    const { current, final, onClick = () => {}, withoutClick, mobile } = this.props;
    if (mobile && final) {
      this.scrollToItem(index);
    }
    if (mobile || (final && withoutClick)) return;
    this.setState({ initial: false, scroll: true });
    if (!final && index < current) {
      onClick(index);
      this.scrollToItem(index, current - 2);
      this.setState({ scroll: false });
      return;
    }
    if (index !== 0) {
      // viewportStore.isTablet || viewportStore.isMobile || viewportStore.isMobileL || final
      // ?
      this.scrollToItem(index);
      // : this.scrollToItem(index, current - 1);
    }
    this.setState({ scroll: false });
  };

  onMouseOutHandler = () => {
    this.scrollerSelected = false;
    this.enablePageScrolling();
  };
  onMouseOverHandler = () => {
    this.pageY = window.scrollY; // for inspector mode browser
    this.scrollerSelected = true;
    this.disablePageScrolling();
  };

  getTopPos = (num) => {
    let res = 0;
    const itemElem = document.getElementById(`item-${num}`);
    if (itemElem) {
      res += itemElem.offsetHeight;
    }
    return -res;
  };

  getHeight = (num) => {
    const elem = this.scroller;
    if (elem) {
      return (elem.offsetHeight / 100) * num;
    }
    return 0;
  };

  render() {
    const { initial, scroll } = this.state;
    const { data, current, final, disabled, mobile } = this.props;
    let { firstCup = 40 } = this.props;
    // const mobile = viewportStore.isMobile || viewportStore.isMobileL;
    if (mobile) {
      // if (firstCup > 10) {
      firstCup = 0;
      // }
      if (disabled || final) {
        // console.log('wtf');
        this.scrollerSelected = false;
        this.enablePageScrolling();
      } else {
        this.scrollerSelected = true;
        this.disablePageScrolling();
      }
    }
    // console.log(disabled);

    return (
      <div
        id="scroller"
        onMouseLeave={this.onMouseOutHandler}
        onMouseOver={this.onMouseOverHandler}
        ref={(el) => {
          this.scroller = el;
        }}
      >
        <div
          className="scroller-overflow"
          id="scrollerOverflow"
          style={{ overflowY: disabled && !mobile ? 'hidden' : final ? 'auto' : '' }}
        >
          <div className="scroller-content">
            {data.map((item, index) => {
              const minHeight = initial
                ? mobile
                  ? '0px'
                  : '1000px'
                : current >= index
                ? index === 0
                  ? current !== 0
                    ? '0px'
                    : `${this.getHeight(firstCup)}px`
                  : '0px'
                : mobile
                ? '0px'
                : '1000px';
              const opacity = final ? '1' : current === index ? '1' : current - index === 1 ? '0.5' : '0.4';
              let height = '';
              const parent = document.getElementById('scrollerOverflow');
              if (!final && mobile) {
                if (parent) {
                  this.itemsHeight = parent.offsetHeight;
                } else if (this.scroller) {
                  this.itemsHeight = this.scroller.offsetHeight;
                }
                height = this.itemsHeight;
              }
              if (mobile && current === index && !final) {
                this.scrollToItem(index);
              }
              return (
                <React.Fragment key={index}>
                  {item && <div className="cup" style={{ minHeight, backgroundColor: 'white' }} />}
                  <Element
                    className={`item`}
                    id={`item-${index}`}
                    name={`item-${index}`}
                    style={{
                      opacity,
                      height,
                    }}
                  >
                    <div className="item-fix" style={{ width: '100%' }} onClick={this.onClickHandler(index)}>
                      {item}
                    </div>
                  </Element>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionScroller;
