import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { ReactComponent as Arrow } from '../../assets/icons/selectArrow.svg';

export default ({ placeholder, options, onChange, onFocus, onBlur, focus, label, error, autoFocus, disabled }) => {
  const [focused, setFocus] = useState(false);
  const [selected, toggleSelect] = useState(false);
  const [currentOption, setCurrent] = useState(-1);
  const selectMain = useRef(null);

  const onFocusHandler = () => {
    if (focused) setFocus(false);
    else {
      setFocus(true);
      if (autoFocus) {
        selectMain.current.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    }
    if (typeof onFocus === 'function') onFocus(true);
  };

  useEffect(() => {
    if (disabled) {
      setFocus(false);
    } else if (focus) {
      setFocus(true);
    }
  }, [focus, disabled]);

  const createClickHandler = (index) => () => {
    setCurrent(index);
    if (typeof onChange === 'function') onChange(options[index]);
    setFocus(false);
    toggleSelect(true);
  };

  const selectClassName = classNames({ focus: focused, error });
  return (
    <div className="select-main" ref={selectMain}>
      <span className="select-label">
        <span>{label}</span>
      </span>
      <div
        className={`select-bordered ${selectClassName}`}
        onClick={onFocusHandler}
        onBlur={onBlur}
        role="button"
        onKeyDown={onFocusHandler}
        tabIndex={0}
      >
        <div className={`select-inp ${focused ? 'focused' : ''}`}>
          <span className={selected ? 'selected' : ''}>
            {currentOption > -1 ? options[currentOption] : placeholder}
          </span>
          <Arrow className={focused ? 'focused' : ''} />
        </div>
      </div>
      <div className={`select-container ${focused ? 'focused-cont' : ''}`}>
        {options.map((option, index) => (
          <div
            className={`select-mini-option ${index === currentOption ? 'checked-mini' : ''}`}
            role="button"
            tabIndex={0}
            onKeyDown={createClickHandler(index)}
            onClick={createClickHandler(index)}
            key={`${option}`}
          >
            {option}
          </div>
        ))}
      </div>
      <div className="select-error">{error}</div>
    </div>
  );
};
