export const FOCUS_RECT_CONFIG = {
  NONE: 'none',
  SHOULDERS: 'shoulders',
  CHEST: 'chest',
  CHEST_SIZE: 'chest_size',
  WAIST: 'waist',
  LEGS: 'legs',
};

export const SIZE_TYPES = {
  SMALLEST: 'smallest',
  SMALL: 'small',
  MEDIUM: 'medium',
  BIG: 'big',
  BIGGEST: 'biggest',
};

export const SHORT_SIZE_TYPES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  BIG: 'big',
};

export const SORTED_SIZE_TYPES = [
  SIZE_TYPES.SMALLEST,
  SIZE_TYPES.SMALL,
  SIZE_TYPES.MEDIUM,
  SIZE_TYPES.BIG,
  SIZE_TYPES.BIGGEST,
];

export const BAND_SIZES = [28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48];

export const CUP_SIZES = ['AA', 'A', 'B', 'C', 'D', 'E (DD)', 'F (DDD)', 'G (DDDD)', 'H (DDDDD)', 'I (DDDDDD)'];
