import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import TagManager from 'react-gtm-module';
import MainTemplate from './components/templates/MainTemplate';
import FindingClothesSection from './components/pages/FindingClothesSection';
import sessionStore from './stores/sessionStore';
import AreYouInSection from './components/pages/AreYouInSection';
import BodySection from './components/pages/BodySection/BodySection';
import viewportStore from './stores/viewportStore';
import FavoritBrands from './components/pages/FavoritBrands';
import PerfectFitSection from './components/pages/PerfectFitSection';
import { SECTIONS } from './stores/sessionStore/config';
import EveryDaySection from './components/pages/EverydaySection/EveryDaySection';
import documentOffsetTop, { documentOffsetTopWithHeader } from './utils/documentOffsetTop';

const tagManagerArgs = {
  gtmId: 'GTM-P5XPPFH',
};

TagManager.initialize(tagManagerArgs);

export default observer(() => {
  const { routes } = sessionStore;
  const title = useRef(null);
  const header = useRef(null);

  const sizeListener = () => {
    viewportStore.setViewPort(window.innerWidth);
  };

  const scrollToHeader = () => {
    const mobile = viewportStore.isMobile || viewportStore.isMobileL;
    setTimeout(() => {
      if (mobile) window.scrollTo({ top: documentOffsetTop(header.current) });
      else window.scrollTo({ top: documentOffsetTopWithHeader(title.current) });
    }, 300);
  };

  useEffect(() => {
    viewportStore.setViewPort(window.innerWidth);
    window.addEventListener('resize', sizeListener);
    scrollToHeader();
    return () => {
      window.removeEventListener('resize', sizeListener);
    };
  }, []);

  useEffect(() => {
    scrollToHeader();
  }, [routes[SECTIONS.MAIN]]);

  const sections = [
    <FindingClothesSection />,
    <AreYouInSection />,
    <BodySection />,
    <FavoritBrands />,
    <PerfectFitSection />,
    <EveryDaySection />,
  ];
  return (
    <div className="App">
      <div className="layout" />
      <MainTemplate titleRef={title} headerRef={header}>
        {sections[routes[SECTIONS.MAIN]]}
      </MainTemplate>
    </div>
  );
});
