/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useTrail, config, animated } from 'react-spring';
import { ReactComponent as Arrow } from '../../assets/icons/selectArrow.svg';
import composedPathPolyfill from '../../utils/composedPathPolyfill';

export default ({ placeholder, options, onChange, onFocus, withoutChange, onBlur, disabled, id }) => {
  const [focused, setFocus] = useState(false);
  const [selected, toggleSelect] = useState(false);
  const [currentOption, setCurrent] = useState(-1);
  const [closed, toggleStatus] = useState(true);

  const onOutsideClick = elemId => e => {
    // console.log("outside click", e.composedPath, e.path);
    let path = null;
    if (e.composedPath) {
      path = Array.from(e.composedPath());
    } else {
      path = composedPathPolyfill(e);
    }

    if (Array.from(path).find(el => el.id && el.id.match(`select-${elemId}`))) {
      // console.log('NANI ?');
      toggleStatus(false);
    } else {
      onBlur(elemId);
      toggleStatus(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onOutsideClick(id));
    let currentIndex = -1;
    options.find((elem, ind) => {
      if (elem.name === placeholder) {
        currentIndex = ind;
      }
      return elem.name === placeholder;
    });
    setCurrent(currentIndex);
    return () => {
      document.removeEventListener('click', onOutsideClick(id));
    };
  }, []);

  useEffect(() => {
    if (disabled) {
      setFocus(false);
    } else if (closed) {
      setFocus(false);
    } else {
      setFocus(true);
    }
  });

  const trail = useTrail(options.length, {
    config: config.gentle,
    opacity: focused ? 1 : 0,
    x: focused ? 0 : 10,
    height: focused ? 10 : 0,
    from: { opacity: 0, x: 10, height: 0 }
  });

  const onFocusHandler = () => {
    if (withoutChange) return;
    setFocus(true);
    if (typeof onFocus === 'function') onFocus(true);
  };

  const createClickHandler = index => () => {
    setCurrent(index);
    toggleSelect(true);
    if (typeof onChange === 'function') onChange(options[index].name);
    setTimeout(() => {
      toggleStatus(true);
      setFocus(false);
    }, 300);
  };

  // console.log(focused, selected, 'IE !! ');

  return (
    <div
      className="select-mini"
      onFocus={onFocusHandler}
      role="button"
      onKeyDown={onFocusHandler}
      tabIndex={0}
      onBlur={onBlur}
      id={`select-${id}`}
    >
      <div className={`select-inp ${focused || selected ? 'focused' : ''}`}>
        <span>{placeholder}</span>
        <Arrow className={focused ? 'focused' : ''} />
        <span className="border" />
      </div>
      <div className={`select-container ${focused ? 'focused-cont' : ''}`}>
        {focused &&
          trail.map(({ x, height, ...rest }, index) => (
            <animated.div
              key={options[index].name}
              style={{ ...rest, transform: x.interpolate(xCoord => `translate3d(0,${xCoord}px,0)`) }}
            >
              <div
                style={{ height }}
                className={`select-mini-option ${index === currentOption ? 'checked-mini' : ''}`}
                role="button"
                tabIndex={0}
                onKeyDown={createClickHandler(index)}
                onClick={createClickHandler(index)}
              >
                {options[index].name}
              </div>
            </animated.div>
          ))}
      </div>
    </div>
  );
};
